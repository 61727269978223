// Theming
a {
    color: var(--link-text, blue);

    &:hover {
        color: var(--link-text-hover, lightblue);
        text-decoration: underline var(--link-text-hover) double;
    }
}

.hamburger {
    color: var(--secondary-text, white);
}

.nav-back {
    background-color: var(--primary-color, lightblue);
}

.nav-button {
    color: var(--secondary-text, white);

    &:hover {
        color: var(--secondary-text, white);
    }
}

#header {
    background-color: var(--primary-color, darkblue);
    color: var(--secondary-text, white);
}

#footer {
    background-color: var(--secondary-color, darkblue);
}

#footer-credits {
    color: var(--secondary-text, white);
}

// Standard styling
.world-content {
    padding: 24px;
}

.divider {
    display: flex;
    width: 100%;
    margin: 20px 0 40px;

    div {
        flex: 1;

        &:not(:first-child) {
            margin-left: 40px;
        }
    }
}

a {
    cursor: pointer;

    &:hover {
        text-decoration: underline #ff7921 double;
    }
}

h2 {
    margin: 14px 0;
    text-align: center;
}

h3 {
    text-align: center;
}

.left {
    float: left;
}

.right {
    float: right;
}

.center {
    text-align: center;
}

.block {
    display: block;
    margin: 0 auto;
}

.width-30-percent {
    width: 30%;
}

.width-50-percent {
    width: 50%;
}

.width-80-percent {
    width: 80%;
}

.video-container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    min-height: 580px;

    iframe {
        margin: 6px auto;

        &:first-child {
            float: left;
        }

        &:not(:first-child) {
            float: right;
        }
    }
}

.separated-list-elements {
    margin-right: 20px;

    li {
        margin-bottom: 12px;
        padding-bottom: 12px;

        ul {
            li {
                margin: 4px 0;
                padding: 4px 0;
            }
        }
    }
}

.character-image {
    background-size: 250px;
    background-repeat: no-repeat;
    height: 200px;
    margin: 10px 0;
    text-align: center;
}

.character-bottom {
    height: 250px;
    margin: 20px auto;
    display: block;
}

@media screen and (max-width: 960px) {

    .world-content {
        padding: 8px;
    }

    .divider {
        display: block;
        margin: 18px 0;

        div {
            margin: 18px 0;
            border: 8px solid rgba(149, 210, 254, 0.5);
            border-radius: 6px;

            &:not(:first-child) {
                margin-left: 0;
            }
        }
    }

    .separated-list-elements {
        margin-right: 12px;
    }

    .character-bottom {
        height: 180px;
    }

    .width-30-percent {
        width: 60%;
    }

    .width-50-percent {
        width: 80%;
    }

    .width-80-percent {
        width: 90%;
    }
}